<template>
  <div class="mt-3">
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <!--table-->
    <vs-table :class="{ 'animate-pulse': isLoading }" :data="receivers" stripe>
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">No. Komisi</vs-th>
        <vs-th class="whitespace-no-wrap">Nama Staf</vs-th>
        <vs-th class="whitespace-no-wrap">Jabatan</vs-th>
        <vs-th class="whitespace-no-wrap">NPWP</vs-th>
        <vs-th class="whitespace-no-wrap">Komisi</vs-th>
        <vs-th class="whitespace-no-wrap">Harga Dasar Komisi</vs-th>
        <vs-th class="whitespace-no-wrap">Closing Fee</vs-th>
        <vs-th class="whitespace-no-wrap">Total Komisi</vs-th>
        <vs-th class="whitespace-no-wrap">PPh</vs-th>
        <vs-th class="whitespace-no-wrap">PPh Tarif</vs-th>
        <vs-th class="whitespace-no-wrap">Ketentuan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in receivers" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.no_komisi }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.nama_staf }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.nama_role }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.npwp || '-' }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.persen_komisi }}%</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.harga_dasar_komisi | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.closing_fee | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.total_komisi | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.pph_persen }}%</vs-td>
          <vs-td class="whitespace-no-wrap"><span class="whitespace-no-wrap text-primary font-semibold underline text-left cursor-pointer" @click="showModalTermin(item)">{{ item.termins.length }} Ketentuan</span></vs-td>
        </vs-tr>
        <vs-tr v-if="data.length > 0" class="text-sm">
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold">TOTAL</vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold text-right">{{ footer.grandTotalHargaDasarKomisi | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap font-bold text-right">{{ footer.grandTotalClosingFee | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap font-bold text-right">{{ footer.grandTotalKomisi | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap font-bold text-right">{{ footer.grandTotalPph | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
          <vs-td class="whitespace-no-wrap font-bold"></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!--modal termin komisi-->
    <TerminKomisi :isActive.sync="modalTermin.active" :termins="modalTermin.termins"/>

  </div>
</template>

<script>
import KontrakKomisiRepository from '@/repositories/marketing/kontrak/kontrak-komisi-repository'
import TerminKomisi from '@/views/pages/marketing/kontrak/tabs-detail/kontrak-komisi/TerminKomisi'
import _ from 'lodash'

export default {
  name: 'TabKomisiD',
  props: ['isActive'],
  components: {
    TerminKomisi
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      receivers: [],
      modalTermin: {
        active: false,
        rowUuid: null,
        termins: []
      }
    }
  },
  computed: {
    footer () {
      return {
        grandTotalHargaDasarKomisi: _.sumBy(this.receivers, item => item.harga_dasar_komisi),
        grandTotalClosingFee: _.sumBy(this.receivers, item => item.closing_fee),
        grandTotalKomisi: _.sumBy(this.receivers, item => item.total_komisi),
        grandTotalPph: _.sumBy(this.receivers, item => item.pph_jml)
      }
    }
  },
  methods: {
    initData () {
      this.getData()
      this.isDataInited = true
    },

    getData () {
      this.isLoading = true
      const idKontrak = this.$route.params.idKontrak

      KontrakKomisiRepository.get(idKontrak)
        .then(response => {
          this.receivers = _.map(response.data.data.receivers, item => {
            const additionalKeys = { isShowTermins: false }
            return _.extend({}, item, additionalKeys)
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showModalTermin (item) {
      this.modalTermin.rowUuid = item.uuid
      this.modalTermin.termins = item.termins
      this.modalTermin.active = true
    }
  }
}
</script>
